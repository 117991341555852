define("ember-svg-jar/inlined/calm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><circle cx=\"67\" cy=\"67\" r=\"67\" fill=\"#fff\" stroke=\"#707070\"/></clipPath></defs><g data-name=\"1F60A\" clip-path=\"url(#a)\"><circle cx=\"67\" cy=\"67\" r=\"67\" fill=\"rgba(55,188,155,0.75)\"/><path d=\"M67.001 106.957a32.235 32.235 0 01-20.71-7.52 2.913 2.913 0 113.735-4.471 26.456 26.456 0 0033.948 0 2.913 2.913 0 113.735 4.471 32.235 32.235 0 01-20.708 7.52z\"/><path data-name=\"Left eye\" d=\"M41.912 66.344a15.618 15.618 0 01-6.756-1.535 16.5 16.5 0 01-8.386-8.414 2.913 2.913 0 115.3-2.411 10.73 10.73 0 005.548 5.547 10.051 10.051 0 007.842.354 11.3 11.3 0 006.37-5.909 2.915 2.915 0 115.3 2.427 17.166 17.166 0 01-9.677 8.957 16.232 16.232 0 01-5.541.984z\"/><path data-name=\"Right eye\" d=\"M92.016 66.344a15.618 15.618 0 01-6.756-1.535 16.5 16.5 0 01-8.386-8.414 2.913 2.913 0 115.3-2.411 10.73 10.73 0 005.545 5.545 10.051 10.051 0 007.842.354 11.3 11.3 0 006.365-5.907 2.915 2.915 0 015.3 2.427 17.166 17.166 0 01-9.677 8.957 16.232 16.232 0 01-5.533.984z\"/><path data-name=\"Right eyebrow\" d=\"M103.368 39.155a10.5 10.5 0 00-3.1-3.048c-3.978-2.805-9.328-4.155-13.117-4.793a2 2 0 01-1.64-2.305 2 2 0 012.305-1.64 44.36 44.36 0 019.286 2.54 26.9 26.9 0 015.827 3.187 13.167 13.167 0 014.01 4.26l-3.587 1.77.016.029z\"/><path data-name=\"Left eyebrow\" d=\"M47.637 27.42a2 2 0 011.417 1.59 2 2 0 01-1.64 2.304c-13.096 2.207-16.172 7.761-16.198 7.812a2 2 0 01-2.679.907 2 2 0 01-.908-2.678 13.167 13.167 0 014.01-4.26 26.9 26.9 0 015.827-3.187 44.359 44.359 0 019.288-2.538 1.992 1.992 0 01.883.05z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "134",
      "height": "134",
      "viewBox": "0 0 134 134"
    }
  };
  _exports.default = _default;
});