define("ember-svg-jar/inlined/worried", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><circle data-name=\"Ellipse 68\" cx=\"67\" cy=\"67\" r=\"67\" fill=\"#fff\" stroke=\"#707070\"/></clipPath></defs><g data-name=\"1F615\" clip-path=\"url(#a)\"><path data-name=\"Path 1901\" d=\"M67 0a67 67 0 1067 67A66.97 66.97 0 0067 0z\" fill=\"rgba(255,206,84,0.75)\"/><path data-name=\"Path 1902\" d=\"M40.849 98.443a2.939 2.939 0 01-1.245-5.6c19.381-9.045 34.777-10.713 53.14-5.758a2.939 2.939 0 01-1.531 5.674c-16.891-4.558-31.123-2.991-49.123 5.409a2.929 2.929 0 01-1.241.275z\"/><path data-name=\"Path 1903\" d=\"M48.781 52.016a8.816 8.816 0 11-8.816-8.816 8.658 8.658 0 018.816 8.816\"/><path data-name=\"Path 1904\" d=\"M101.676 52.016A8.816 8.816 0 1192.86 43.2a8.658 8.658 0 018.816 8.816\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "134",
      "height": "134",
      "viewBox": "0 0 134 134"
    }
  };
  _exports.default = _default;
});