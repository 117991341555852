define("ember-svg-jar/inlined/mad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><circle cx=\"67\" cy=\"67\" r=\"67\" fill=\"#fff\" stroke=\"#707070\"/></clipPath></defs><g data-name=\"1F641\" clip-path=\"url(#a)\"><path d=\"M67 0a67 67 0 1067 67A67.01 67.01 0 0067 0z\" fill=\"rgba(237,85,101,0.75)\"/><path data-name=\"Left eye\" d=\"M52.521 52.431a8.739 8.739 0 11-8.739-8.739 8.583 8.583 0 018.739 8.739\"/><path data-name=\"Right eye\" d=\"M98.956 52.431a8.739 8.739 0 11-8.739-8.739 8.583 8.583 0 018.739 8.739\"/><path data-name=\"Mouth\" d=\"M43.692 99.044a2.914 2.914 0 01-2.478-4.44c5.151-8.371 15.51-13.367 27.034-13.037 10.1.316 19.483 5.286 24.5 12.973a2.913 2.913 0 01-4.88 3.183c-3.928-6.023-11.7-10.079-19.79-10.332-9.3-.266-17.9 3.764-21.9 10.267a2.911 2.911 0 01-2.486 1.386z\"/><path data-name=\"Left eyebrow\" d=\"M57.5 52.5a2.989 2.989 0 01-1.207-.255l-25-11a3 3 0 01-1.539-3.953 3 3 0 013.954-1.538l25 11A3 3 0 0157.5 52.5z\"/><path data-name=\"Right eyebrow\" d=\"M76.5 52.5a3 3 0 01-2.748-1.793 3 3 0 011.54-3.953l25-11a3 3 0 013.954 1.538 3 3 0 01-1.538 3.954l-25 11a2.99 2.99 0 01-1.208.254z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "134",
      "height": "134",
      "viewBox": "0 0 134 134"
    }
  };
  _exports.default = _default;
});