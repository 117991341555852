define("ember-svg-jar/inlined/check-in-banner-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Path 4630\" d=\"M12 15.75a8.185 8.185 0 001.355 4.528l-.258.267a1.5 1.5 0 01-2.152 0L1.861 11.18a6.733 6.733 0 01.468-9.742 6.454 6.454 0 018.756.609l.933.983.891-.983a6.508 6.508 0 018.8-.609 6.755 6.755 0 012.053 6.845A8.236 8.236 0 0020.25 7.5 8.25 8.25 0 0012 15.75z\" fill=\"#d770ad\"/><path data-name=\"Path 4631\" d=\"M27 15.75a6.752 6.752 0 01-6.75 6.75 6.752 6.752 0 01-6.75-6.75A6.752 6.752 0 0120.25 9 6.752 6.752 0 0127 15.75zm-4.655-2.03L19.5 16.566l-1.345-1.346a.744.744 0 00-1.059 0 .744.744 0 000 1.059l1.874 1.876a.744.744 0 001.059 0L23.4 14.78a.744.744 0 000-1.059.744.744 0 00-1.055-.001z\" fill=\"#8cc152\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "22.5",
      "viewBox": "0 0 27 22.5"
    }
  };
  _exports.default = _default;
});