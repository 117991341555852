define("ember-svg-jar/inlined/proud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><circle data-name=\"Ellipse 71\" cx=\"67\" cy=\"67\" r=\"67\" fill=\"#fff\" stroke=\"#707070\"/></clipPath></defs><g data-name=\"1F60A\" clip-path=\"url(#a)\"><circle data-name=\"Ellipse 70\" cx=\"67\" cy=\"67\" r=\"67\" fill=\"rgba(150,122,220,0.75)\"/><path data-name=\"Path 1898\" d=\"M67.001 106.942a47.7 47.7 0 01-30.4-10.894 2.915 2.915 0 013.706-4.5 42.031 42.031 0 0053.389 0 2.915 2.915 0 113.706 4.5 47.7 47.7 0 01-30.401 10.894z\"/><path data-name=\"Path 1899\" d=\"M29.421 62.34a2.914 2.914 0 01-2.65-4.119 16.542 16.542 0 018.438-8.438 15.765 15.765 0 0112.237-.528 17.166 17.166 0 019.677 8.957 2.915 2.915 0 11-5.3 2.427 11.3 11.3 0 00-6.362-5.908 10.017 10.017 0 00-7.791.33 10.773 10.773 0 00-5.6 5.569 2.914 2.914 0 01-2.649 1.71z\"/><path data-name=\"Path 1900\" d=\"M79.525 62.34a2.914 2.914 0 01-2.65-4.119 16.542 16.542 0 018.438-8.438 15.765 15.765 0 0112.237-.528 17.166 17.166 0 019.677 8.957 2.915 2.915 0 01-5.3 2.427 11.3 11.3 0 00-6.365-5.907 10.017 10.017 0 00-7.791.33 10.773 10.773 0 00-5.6 5.569 2.914 2.914 0 01-2.646 1.709z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "134",
      "height": "134",
      "viewBox": "0 0 134 134"
    }
  };
  _exports.default = _default;
});